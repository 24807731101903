<template>
  <div class="tw-flex tw-justify-center">
    <div class="page-sculptor">
      <div>
        <v-btn class="add-pd" @click="$router.push({name:'AllDepartments'})">
          <icons name="back" class="mr-2" />
          All Departments</v-btn>
      </div>
      <validation-observer ref="form">
        <div class="white-bg">
          <validation-provider name="unit name" rules="required" v-slot="{ classes, errors }">
            <h3 class="text-headings ">Unit Name</h3>
            <v-select type="text" class="text-fields mt-3" placeholder="Design" outlined flat hide-details
              v-model="deptData.tagline" :items="unitNames" dense @input="getSelectedUnitId" />
            <span class="error-text">{{errors[0]}}</span>
          </validation-provider>
        </div>
        <div class="white-bg mt-7">
          <validation-provider name="department name" rules="required" v-slot="{ classes, errors }">
            <h3 class="text-headings ">Department Name</h3>
            <v-text-field type="text" class="text-fields mt-3" placeholder="Input a department name" outlined
              hide-details v-model="deptData.name" dense />
            <span class="error-text">{{errors[0]}}</span>
          </validation-provider>
          <div class="mt-7">
            <validation-provider name="department description" rules="required" v-slot="{ classes, errors }">
              <h3 class="text-headings ">Department Description - Role</h3>
              <v-textarea type="text" class="text-fields mt-3" placeholder="Design Unit deals with everything design"
                outlined hide-details v-model="deptData.description" dense rows="3" auto-grow />
              <span class="error-text">{{errors[0]}}</span>
            </validation-provider>
          </div>
        </div>
        <div class="buttons">
          <v-btn text class="ctrl-btn back" :loading="loading2" @click="clearAndAddAnother">Add another</v-btn>
          <v-btn text class="ctrl-btn next" @click="saveData" :loading="loading1">
            Done
          </v-btn>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
  import Icons from '../../../components/reuseables/Icons.vue'
  import {
    getAllUnits,
    addNewDepartment
  } from '../../../services/api/APIService';
  export default {
    components: {
      Icons
    },
    name: "AddDepartment",
    data() {
      return {
        deptData: {
          tagline: '',
          name: '',
          description: ''
        },
        newTag: "",
        tagError: "",
        loading1: false,
        loading2: false,
        unitData: []
      }
    },

    methods: {
      async getAllUnits() {
        await getAllUnits().then(res => {
          this.unitData = res.data
        }).catch(err => {
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false
          })
        })
      },
      getSelectedUnitId(selectedUnitName) {
        const selectedUnit = this.unitData.find(unit => unit.name === selectedUnitName)
        this.deptData.unitId = selectedUnit.id;
      },
      async saveData() {
        this.loading1 = true
        let data = {}
        data.tagline = this.deptData.tagline
        data.name = this.deptData.name
        data.description = this.deptData.description
        data.createdBy = "lois"
        data.unitId = this.deptData.unitId
        await addNewDepartment(data).then(() => {
          this.loading1 = false
          this.$displaySnackbar({
            message: "Department added successfully",
            success: true
          })
        }).catch(err=>{
          this.$displaySnackbar({
            message:  err.response.data.details[0],
            success: false
          })
        })
          this.$router.push({
            name: "AllDepartments"
          })
          window.location.reload()
        },
      clearAndAddAnother() {
        this.loading2 = true
        let data = {}
        data.tagline = this.deptData.tagline
        data.name = this.deptData.name
        data.description = this.deptData.description
        data.createdBy = "lois"
        data.unitId = this.deptData.unitId
        addNewDepartment(data).then(() => {
          this.loading2 = false
          this.$displaySnackbar({
            message: "Department added successfully",
            success: true
          })
          this.deptData = {
          tagline: '',
          name: '',
          description: '',
          unitId: ''
        }
        this.$refs.form.reset();
        })

      }
    },
    async created() {
      await this.getAllUnits()
    },
    computed: {
      unitNames() {
        return this.unitData.map(unit => unit.name);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-sculptor {
    display: flex;
    flex-direction: column;
    width: 700px;

    @media (max-width:768px) {
      width: 100%;
    }
  }

  .text-headings {
    color: #4F4F4F;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .v-text-field input {
    color: #C4C4C4;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
  }

  .tag-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 10px;
    background-color: #fff;
  }

  .tag {
    border-radius: 5px;
    background: rgba(119, 202, 241, 0.18);
    color: #4F4F4F;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 7px 12px;
    text-transform: capitalize;
  }

  .remove-tag {
    cursor: pointer;
    margin-left: 8px;
    font-weight: bold;
  }

  .add-pd {
    color: #263238;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: none;
    letter-spacing: 0;
    margin-bottom: 15px;

    @media (max-width:768px) {
      justify-content: start;
      margin-top: 13px;
      font-size: 17px;
    }
  }

  .white-bg {
    background-color: #fff;
    padding: 30px;
    height: fit-content;
    border-radius: 10px;

    @media (max-width:768px) {
      padding: 15px;
    }
  }

  .ctrl-btn {
    width: 110px;
    height: 30px;
    border-radius: 10px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize !important;
    letter-spacing: 0px;

    @media (max-width:400px) {
      width: 100%;
    }
  }

  .buttons {
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;

    @media (max-width:768px) {
      margin-top: 30px;
    }

    @media (max-width:400px) {
      flex-direction: column;
    }
  }

  ::v-deep .v-btn.next {
    color: #ffff;
    background-color: #004AAD !important;
    margin-left: 20px;

    @media (max-width:400px) {
      margin-left: 0;
      margin-top: 20px;
    }
  }

  ::v-deep .v-btn.back {
    color: #004AAD;
    background-color: #ffff;
    border: 2px solid #004AAD;
  }
</style>