var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-justify-center"},[_c('div',{staticClass:"page-sculptor"},[_c('div',[_c('v-btn',{staticClass:"add-pd",on:{"click":function($event){return _vm.$router.push({name:'AllDepartments'})}}},[_c('icons',{staticClass:"mr-2",attrs:{"name":"back"}}),_vm._v(" All Departments")],1)],1),_c('validation-observer',{ref:"form"},[_c('div',{staticClass:"white-bg"},[_c('validation-provider',{attrs:{"name":"unit name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('h3',{staticClass:"text-headings "},[_vm._v("Unit Name")]),_c('v-select',{staticClass:"text-fields mt-3",attrs:{"type":"text","placeholder":"Design","outlined":"","flat":"","hide-details":"","items":_vm.unitNames,"dense":""},on:{"input":_vm.getSelectedUnitId},model:{value:(_vm.deptData.tagline),callback:function ($$v) {_vm.$set(_vm.deptData, "tagline", $$v)},expression:"deptData.tagline"}}),_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"white-bg mt-7"},[_c('validation-provider',{attrs:{"name":"department name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('h3',{staticClass:"text-headings "},[_vm._v("Department Name")]),_c('v-text-field',{staticClass:"text-fields mt-3",attrs:{"type":"text","placeholder":"Input a department name","outlined":"","hide-details":"","dense":""},model:{value:(_vm.deptData.name),callback:function ($$v) {_vm.$set(_vm.deptData, "name", $$v)},expression:"deptData.name"}}),_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('div',{staticClass:"mt-7"},[_c('validation-provider',{attrs:{"name":"department description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('h3',{staticClass:"text-headings "},[_vm._v("Department Description - Role")]),_c('v-textarea',{staticClass:"text-fields mt-3",attrs:{"type":"text","placeholder":"Design Unit deals with everything design","outlined":"","hide-details":"","dense":"","rows":"3","auto-grow":""},model:{value:(_vm.deptData.description),callback:function ($$v) {_vm.$set(_vm.deptData, "description", $$v)},expression:"deptData.description"}}),_c('span',{staticClass:"error-text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"buttons"},[_c('v-btn',{staticClass:"ctrl-btn back",attrs:{"text":"","loading":_vm.loading2},on:{"click":_vm.clearAndAddAnother}},[_vm._v("Add another")]),_c('v-btn',{staticClass:"ctrl-btn next",attrs:{"text":"","loading":_vm.loading1},on:{"click":_vm.saveData}},[_vm._v(" Done ")])],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }